import { template as template_db596158d4254170a4a8d0cb169912dc } from "@ember/template-compiler";
const SidebarSectionMessage = template_db596158d4254170a4a8d0cb169912dc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
