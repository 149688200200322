import { template as template_688dab70b492424a8b7e4ae9cf31e790 } from "@ember/template-compiler";
const WelcomeHeader = template_688dab70b492424a8b7e4ae9cf31e790(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
