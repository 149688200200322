import { template as template_0cc10b87a01446c6868f4b152028103b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_0cc10b87a01446c6868f4b152028103b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
