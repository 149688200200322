import { template as template_80112c2e182745f4996e2ea5ab0f3aa4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_80112c2e182745f4996e2ea5ab0f3aa4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
